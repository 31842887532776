// import external modules
import { combineReducers } from 'redux'

// import internal(own) modules
import emailContent from './emailContent'
import emails from './emails'
import emailSearch from './emailSearch'
import selectedEmail from './selectedEmail'
import visibilityFilter from './visibilityFilter'

const emailReducer = combineReducers({
	emails,
	visibilityFilter,
	selectedEmail,
	emailSearch,
	emailContent,
})

export default emailReducer
