import { combineReducers } from 'redux'

const init_state = {
	token: '',
}
const user = (state = init_state, action = {}) => {
	switch (action.type) {
		case 'SET_TOKEN':
			// console.log({...action})
			return { ...state, token: action.token }
		default:
			return state
	}
}
export default combineReducers({
	user,
})

// export default user
