// import external modules
import { reducer as toastrReducer } from 'react-redux-toastr'

import { combineReducers } from 'redux'

// import internal(own) modules
import calenderReducer from './calenderReducer'
// import chatReducer from "./chatReducer";
import chatReducer from './chat'
import contactsReducer from './contacts'
import customizer from './customizer'
import emailReducer from './email'
import todoReducer from './todo'
import user from './user'
import analyticsReducer from './analytics'

const rootReducer = combineReducers({
	calender: calenderReducer,
	emailApp: emailReducer,
	contactApp: contactsReducer,
	todoApp: todoReducer,
	toastr: toastrReducer, // <- Mounted at toastr.
	chatApp: chatReducer,
	customizer,
	user,
	analytics: analyticsReducer,
})

export default rootReducer
