// import external modules
import { combineReducers } from 'redux'

// import internal(own) modules
import sidebarBgColor from './sidebarBgColor'
import sidebarCollapsed from './sidebarCollapsed'
import sidebarImage from './sidebarImage'
import sidebarImageUrl from './sidebarImageUrl'
import sidebarSize from './sidebarSize'

const emailReducer = combineReducers({
	sidebarBgColor,
	sidebarImage,
	sidebarImageUrl,
	sidebarSize,
	sidebarCollapsed,
})

export default emailReducer
