import './index.scss'
import 'font-awesome/css/font-awesome.min.css'
import React, { Suspense, lazy } from 'react'
import { Provider } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import Spinner from './components/spinner/spinner'
import { store } from './redux/storeConfig/store'
import registerServiceWorker from './registerServiceWorker'
import 'react-pivottable/pivottable.css'
import 'sweetalert2/src/sweetalert2.scss'

const LazyApp = lazy(() => import('./app/app'))

const App = () => {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<Suspense fallback={<Spinner />}>
					<LazyApp />
					<ReduxToastr
						timeOut={4000}
						newestOnTop={false}
						preventDuplicates
						position="top-left"
						transitionIn="fadeIn"
						transitionOut="fadeOut"
						progressBar
						closeOnToastrClick
					/>
				</Suspense>
			</BrowserRouter>
		</Provider>
	)
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)

registerServiceWorker()
