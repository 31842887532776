// import external modules
import { combineReducers } from 'redux'

// import internal(own) modules
import chatContent from './chatContent'
import chats from './chats'
import chatSearch from './chatSearch'

const chatReducer = combineReducers({
	chats,
	chatSearch,
	chatContent,
})

export default chatReducer
