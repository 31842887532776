import { combineReducers } from 'redux'

import editTodo from './editTodo'
import todo from './todo'
import todoDetails from './todoDetails'
import todoSearch from './todoSearch'
import todoVisibilityFilter from './todoVisibilityFilter'

export default combineReducers({
	todo,
	todoDetails,
	todoVisibilityFilter,
	todoSearch,
	editTodo,
})
