import { combineReducers } from 'redux'

import contacts from './contacts'
import contactsDetails from './contactsDetails'
import contactsSearch from './contactsSearch'
import contactsVisibilityFilter from './contactsVisibilityFilter'
import editContact from './editContact'

export default combineReducers({
	contacts,
	contactsDetails,
	contactsVisibilityFilter,
	contactsSearch,
	editContact,
})
