/* eslint-disable */
const updateData = (state = '', action) => {
	switch (action.type) {
		case 'REFRESH_DATA':
			return { ...state, updateData: action.payload, loading: false }
		case 'LOADING_DATA':
			return { ...state, updateData: '', loading: action.payload }
		case 'UPDATE_LOADING':
			return { ...state, loading: action.payload }
		case 'CLEAR_DATA':
			return { ...state, updateData: '', loading: false }
		default:
			return state
	}
}

export default updateData
